/*
 * Copyright 2017 General Code
 */

:root {
  --content-preview-width: 400px;
  --content-preview-height: 264px;
  --mouse-x: 0;
  --mouse-y: 0;
}

table, thead, tbody, th, tr, td {
  border: none;
}

.questionText > .table > table > thead {
  background: lightgrey;
}

.questionText > .table > table > thead > tr > th {
  font-weight: bold;
}

table {
  float: none;
}

dl {
  margin: 0;
}

.highlight {
  background-color: #ffff00;
  padding: 0 2px;
  margin-left: -1px;
  margin-right: -1px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  display: inline-block;
  text-decoration: inherit;
  font-weight: inherit;
}

em.highlight {
  font-style: normal;
}

#titleBox .highlight {
  background-color: #646464;
}

.eCodeSelection {
  display: inline;
  background-color: #FFFF99;
}

.ecodePrint .highlight, .ecodePrint .eCodeSelection, .hideHighlight .highlight, .hideHighlight .eCodeSelection {
  background-color: transparent;
}

#displayDate {
  margin-bottom: 18px;
  margin-top: 19px;
}

#displayDateLink {
  text-decoration: none;
  color: #666;
}

.adopted, .supped {
  font-style: italic;
}

.attachments {
  margin-bottom: 24px;
}

.attachments dd {
  margin-left: 0;
}

#codePageTitleArea {
  flex: 1 1 auto;
  overflow: hidden;
}

#codePageTitleArea #pageTitleContainer {
  height: 100%;
  align-items: center;
}

#codePageTitleArea #pageTitle {
  margin-bottom: 0;
}

#searchContextWidget.page-control-widget .page-control-widget-content {
  display: flex;
}

#searchContextInfo {
  flex: 1 1 auto;
}

#searchContextQuery {
  font-variant: proportional-nums lining-nums;
  font-style: italic;
  font-size: 1rem;
  font-weight: 500;
  flex: 0 1 auto;
  align-items: center;
  flex-direction: column;
  background-color: #ffff00;
}

#searchContextControls {
  display: flex;
  font-size: 0.91rem;
}

#searchContextClear, #searchContextReturn {
  font-weight: 600;
  text-decoration: none;
  color: #333;
}

#searchContextClear:hover, #searchContextReturn:hover {
  text-decoration: underline;
  color: #111;
}

.searchContextCount {
  font-variant-numeric: proportional-nums lining-nums;
}

#resultContextPrev {
  margin-right: 4px;
}

#resultContextNext {
  margin-left: 4px;
}

#tocWidget.page-control-widget .page-control-widget-header {
  display: flex;
  width: 100%;
}

#gpsPrev {
  margin-left: -4px;
  margin-right: 4px;
  text-align: left;
}

#gpsPrevToolbar, #gpsNextToolbar {
  text-align: center;
}

#gpsPrevToolbar {
  border-right: 1px solid #CFCFCF;
}

#gpsNextToolbar {
  border-left: 1px solid #CFCFCF;
}

#gpsNext {
  margin-right: -4px;
  margin-left: 4px;
  text-align: right;
}

.toolbarSpacer {
  flex: 1 1 auto;
}

.resultContextNav {
  flex: 0 0 auto;
  display: block;
  align-self: center;
  color: #666;
  font-weight: 600;
  text-decoration: none;
  font-size: 1rem;
}

.resultContextNav:hover {
  color: #333;
  text-decoration: underline;
}

.titleLink, .link, .part_title {
  text-decoration: none;
  color: #333;
  font-weight: normal;
}

#pageTitle .link {
  display: flex;
  align-items: center;
}

/*noinspection CssUnknownProperty*/
dl .toc .titleLink {
  -webkit-column-break-after: auto;
  -moz-column-break-after: auto;
  column-break-after: auto;
}

.xref:hover, .xref:hover .highlight, .extref:hover, .extref:hover .highlight, .nonxml:hover, .nonxml:hover .highlight, #notesLink:hover, #notesLink:hover .highlight, .nonxmlref:hover, .nonxmlref:hover .highlight, .law:hover, .law:hover .highlight, .attachmentslink:hover, .attachmentslink:hover .highlight, .codelink:hover, .adhocLink:hover {
  text-decoration: underline;
}

div.nonxmlref {
  display: inline;
  font-weight: inherit;
  color: inherit;
}

.content .titleLink:hover {
  color: #005d85;
}

.toc dt {
  display: none;
}

#indexLink, #notesLink {
  display: block;
  color: #333;
  font-weight: bold;
  text-decoration: none;
}

.toc.title, .title {
  font-size: 24px;
  margin-top: 16px;
}

body.contentPage .title {
  font-size: 28px;
  margin-top: 10px;
}

.toc.chapterTitle {
  margin-top: 32px;
}

.title.chapterrefblockTitle {
  font-size: 13px;
  margin-bottom: 0;
  margin-top: 1em;
}

.definition {
  clear: right;
  margin-top: 16px;
}

.deftext {
  margin-left: 2em;
}

.definition > .deflevel, .definition > .graphic, .definition > .graphicBox, .definition .codeTable, .definition .annotation, .definition .gallery {
  margin-left: 2em;
}

.level ~ .para {
  margin-top: 16px;
}

.para + .para {
  margin-top: 8px;
}

.para, .deftext {
  text-align: justify;
  text-justify: newspaper;
}

.definition .history {
  margin-bottom: 0;
}

.placeholder {
  color: #eee;
}

.newlaws {
  margin-left: 0;
  margin-top: 8px;
  margin-bottom: 2em;
}

.muiPaper.newLawsDescriptionPaper {
  margin-top: -8px;
}

#lawEdit, #lawDelete {
  display: inline-block;
}

#lawAdd + .newlaws {
  margin-top: 8px;
}

.newlaws .lawTitle {
  font-weight: normal;
}

#lawsPrevious, #lawsDescription, #pageDescription {
  margin-bottom: 16px;
  margin-top: -8px;
}

#lawPage form {
  display: inline;
}

#content {
  font-size: 16px;
}

#content-preview {
  position: fixed;
  left: calc(var(--mouse-x) + 8px);
  top: calc(var(--mouse-y) + 8px);
  width: var(--content-preview-width);
  height: var(--content-preview-height);
  overflow: hidden;
  z-index: 1301;
  background-color: white;
  border: 1px solid gray;
  padding: 8px;
}

#content-preview.left {
  left: calc(var(--mouse-x) - (var(--content-preview-width) + 24px));
}

#content-preview.top {
  top: calc(var(--mouse-y) - (var(--content-preview-height) + 16px));
}

#content-preview iframe, #content-preview object {
  width: var(--content-preview-width);
  height: var(--content-preview-height);
  pointer-events: none;
}

.sectionTitle {
  font-size: 22px;
}

.level .content {
  margin-bottom: 16px;
}

.section-page {
  padding-bottom: 6px;
  background-color: #fff;
  margin-top: -3px;
  margin-bottom: 0;
}

.litem1 {
  padding-left: 0.2em;
  text-indent: -1.3em;
}

.litem2 {
  padding-left: 0.8em;
  text-indent: -1.6em;
}

.litem3 {
  padding-left: 1.4em;
  text-indent: -1.6em;
}

.litem4 {
  padding-left: 2.0em;
  text-indent: -1.7em;
}

.litem5 {
  padding-left: 2.5em;
  text-indent: -1.6em;
}

.litem6 {
  padding-left: 2.8em;
  text-indent: -1.4em;
}

.xref, .extref, .nonxml, .law, .nonxmlref, .attachmentslink, .codelink, .adhocLink {
  color: #333;
  text-decoration: none;
  font-weight: 600;
}

.graphic .graphicThumb, .inlinegraphic .graphicThumb {
  max-width: 100%;
}

.gallery .graphic .graphicThumb, .gallery .inlinegraphic .graphicThumb {
  max-width: 100%;
  width: 100%;
  display: block;
}

.para .graphic {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.graphic .title {
  font-size: 16px;
  margin-top: 8px;
  margin-bottom: 0;
}

.graphic .caption {
  font-size: 14px;
}

.graphicBox {
  background-color: var(--paper-background-color);
  border: 1px solid #666;
  padding: 8px;
  float: right;
  width: 355px;
  margin-left: 0.5em;
}

.graphicBox .titleTitle, .graphicBox .titleNumber {
  text-shadow: none;
}

.graphicBox .graphicThumb {
  max-width: 355px;
}

.gallery .graphic, .gallery .graphicBox {
  float: left;
}

.gallery .graphicBox .grapic {
  float: none;
}

.gallery {
  clear: both;
  content: '';
  display: table;
  width: 100%;
}

.content:after {
    clear: both;
    content: '';
    display: block;
}

/**
 *
 * Index Page
 *
 **/
#index #content a {
  color: #333;
  font-weight: normal;
  text-decoration: none;
}

/**
 *
 * Pub only
 *
 **/
#pubOnlyImage {
  background-size: 615px 386px;
  width: 615px;
  height: 386px;
  position: relative;
}

#pubOnlyText {
  position: absolute;
  top: 93px;
  left: 187px;
  width: 205px;
  color: #888;
  font-size: 24px;
  line-height: 30px;
}

/* Table stuff */
.codeTable {
  margin-top: 16px;
}

.codeTable table {
  table-layout: fixed;
  width: 100%;
  border-width: 1px;
  /*border-color: #888;*/
}

.codeTable table td, .codeTable table th {
  hyphens: auto;
}

.codeTable table td.rotate, .codeTable table th.rotate {
  overflow: hidden;
}

table td .para, table th .para {
  hyphens: auto;
  overflow-wrap: break-word;
}

.codeTable td, .codeTable th {
  padding-left: 4px;
  padding-right: 4px;
  padding-bottom: 4px;
  text-align: left;
  border-width: 1px;
  border-color: #888;
}

.codeTable td[align='center'], .codeTable th[align='center'] {
  text-align: center;
}

.codeTable td[align='right'], .codeTable th[align='right'] {
  text-align: right;
}

.codeTable td[align='justify'], .codeTable th[align='justify'] {
  text-align: justify;
}

.codeTable td[valign='top'], .codeTable th[valign='top'] {
  vertical-align: top;
}

.codeTable td[valign='middle'], .codeTable th[valign='middle'] {
  vertical-align: middle;
}

.codeTable td[valign='bottom'], .codeTable th[valign='bottom'] {
  vertical-align: bottom;
}

.codeTable td > .para, .codeTable th > .para {
  text-align: inherit;
}

.codeTable + .para {
  margin-top: 16px;
}

.tableScrollWrapper {
  height: 200px;
  overflow: hidden;
  position: relative;
}

.landTable.lightbox {
  /*animation: spinIn 2s;
  -webkit-animation: spinIn 2s;*/
  -webkit-animation: bounceIn 0.3s ease-in-out;
  animation: bounceIn 0.3s ease-in-out;
  cursor: default;
}

@-webkit-keyframes bounceIn {
  0% {
    transform: scale(0);
    opacity: 0;
  }

  1% {
    transform: scale(0.8);
    opacity: 0.3;
  }

  100% {
    transform: scale(1);
    opacity: 1;
  }
}

@keyframes bounceIn {
  0% {
    transform: scale(0);
  }

  1% {
    transform: scale(0.8);
  }

  100% {
    transform: scale(1);
  }
}

/*img {
  -webkit-animation: imgSpin 20s infinite;
}

@-webkit-keyframes imgSpin {
  0% {-webkit-transform: rotate(360deg);}
  100% {-webkit-transform: rotate(0deg);}
}*/


.landTable .tableTitle {
  padding-left: 0.5em;
  font-weight: 600;
  cursor: pointer;
}

.landTable {
  cursor: pointer;
}

.tableTitle:after {
  display: block;
  content: "(click here to view the full table)";
  font-style: italic;
  font-size: 14px;
  font-weight: 500;
}

@keyframes spinIn {
  0% {
    transform: rotate(720deg) scale(0.3);
  }

  70% {
    transform: rotate(-30deg) scale(1.2);
  }

  90% {
    transform: rotate(0deg) scale(0.8);
  }

  100% {
    transform: rotate(0deg) scale(1);
  }
}

@-webkit-keyframes spinIn {
  0% {
    transform: rotate(720deg) scale(0.3);
  }

  70% {
    transform: rotate(-30deg) scale(1.2);
  }

  90% {
    transform: rotate(0deg) scale(0.8);
  }

  100% {
    transform: rotate(0deg) scale(1);
  }
}

.landTable:not(.lightbox) {
  cursor: pointer;
}

.tableScrollWrapper table {
  width: 1093px;
  border: 1px solid #a8a8a8;
  padding: 5px;
  transform: scale(0.64);
  transform-origin: 0 0;
  cursor: pointer;
}

.landTable .tableScrollWrapper:before {
  content: "";
  position: absolute;
  background-color: #fff;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  opacity: 0.5;
  display: block;
  -moz-transition: opacity 0.2s;
  -webkit-transition: opacity 0.2s;
  -o-transition: opacity 0.2s;
  transition: opacity 0.2s;
  z-index: 1;
}

.landTable .tableScrollWrapper:after {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: block;
  background-image: url(../images/table_expand_small.png);
  background-size: 100px;
  background-position: 10px 90px;
  background-repeat: no-repeat;
  opacity: 0.55;
  -moz-transition: opacity 0.2s;
  -webkit-transition: opacity 0.2s;
  -o-transition: opacity 0.2s;
  transition: opacity 0.2s;
  z-index: 1;
}

.landTable .tableScrollWrapper:hover:before {
  opacity: 0.7;
}

.landTable .tableScrollWrapper:hover:after {
  opacity: 0.7;
}

.unnumberedParaPrevIndent,
.level .wideTable {
  margin-left: -2em;
}

.level .level .wideTable {
  margin-left: -4em;
}

.level .level .level .wideTable {
  margin-left: -6em;
}

.level .level .level .level .wideTable {
  margin-left: -8em;
}

.level .level .level .level .level .wideTable {
  margin-left: -10em;
}

.level .level .level .level .level .level .wideTable {
  margin-left: -12em;
}

.enhancedTable .para, .tableV2 .para {
  margin-top: 0;
  margin-bottom: 0;
}

.enhancedTable.wideTable table {
  font-size: 80%;
}

th {
  font-weight: 400;
}

.enhancedTable th, .tableV2 th, .legacy th, .enhancedTable th .para, .tableV2 th .para, .legacy th .para {
  font-weight: 700;
}

.enhancedTable td.borderTop-solid, .tableV2 td.borderTop-solid,
.enhancedTable th.borderTop-solid, .tableV2 th.borderTop-solid {
  border-top-width: 1px;
  border-top-style: solid;
}

.enhancedTable td.borderTop-none, .tableV2 td.borderTop-none,
.enhancedTable th.borderTop-none, .tableV2 th.borderTop-none {
  border-top-style: none;
}

.enhancedTable td.borderBottom-solid, .tableV2 td.borderBottom-solid,
.enhancedTable th.borderBottom-solid, .tableV2 th.borderBottom-solid {
  border-bottom-width: 1px;
  border-bottom-style: solid;
}

.enhancedTable td.borderBottom-none, .tableV2 td.borderBottom-none,
.enhancedTable th.borderBottom-none, .tableV2 th.borderBottom-none {
  border-bottom-style: none;
}

.enhancedTable td.borderLeft-solid, .tableV2 td.borderLeft-solid,
.enhancedTable th.borderLeft-solid, .tableV2 th.borderLeft-solid {
  border-left-width: 1px;
  border-left-style: solid;
}

.enhancedTable td.borderLeft-none, .tableV2 td.borderLeft-none,
.enhancedTable th.borderLeft-none, .tableV2 th.borderLeft-none {
  border-left-style: none;
}

.enhancedTable td.borderRight-solid, .tableV2 td.borderRight-solid,
.enhancedTable th.borderRight-solid, .tableV2 th.borderRight-solid {
  border-right-width: 1px;
  border-right-style: solid;
}

.enhancedTable td.borderRight-none, .tableV2 td.borderRight-none,
.enhancedTable th.borderRight-none, .tableV2 th.borderRight-none {
  border-right-style: none;
}

.frame {
  border-width: 1px;
}

.frameAll, .borderAll {
  border-style: solid;
}

.frameTop, .frameTopBottom, .borderTop {
  border-top-style: solid;
}

.frameBottom, .frameTopBottom, .borderBottom {
  border-bottom-style: solid;
}

.frameSides, .borderLeft {
  border-left-style: solid;
}

.frameSides, .borderRight {
  border-right-style: solid;
}

.codeTable table.frameNone {
  border-style: none;
  border-width: 0;
}

.enhancedTable table, .tableV2 table {
  border-width: 2px;
}

/*.enhancedTable.landTable table {
  border-top-style: none;
  border-left-style: none;
  border-right-style: none;
}*/

.enhancedTable table, .enhancedTable td, .enhancedTable th, .legacy .frame {
  border-collapse: collapse;
  border-color: #d7d7d7;
}

.floatThead-container thead {
  background-color: #fff;
}

.tableV2 table, .tableV2 td, .tableV2 th, .legacy .frame {
  border-collapse: collapse;
}

.enhancedTable.lightGrayHead .floatThead-container,
.enhancedTable.lightGrayHead thead, .enhancedTable.migration thead {
  background-color: #d7d7d7;
  color: #333;

}

.enhancedTable.blackHead table, .enhancedTable.blackHead th, .enhancedTable.blackHead td, .enhancedTable.bordersOnly table,
.enhancedTable.bordersOnly th, .enhancedTable.bordersOnly td {
  border-color: #333;
}

.enhancedTable.blackHead .floatThead-container,
.enhancedTable.blackHead thead {
  background-color: #333;
  color: #fbfbfb;
}

.enhancedTable .floatThead-container {
  background-color: #fff;
}

/*noinspection CssInvalidHtmlTagReference*/
.codeTable:not(.landTable) fthfoot {
  border-collapse: separate !important;
  visibility: visible !important;
}

.rotate {
  writing-mode: vertical-rl;
  rotate: 180deg;
  align-content: center;
}

/*
.rotate {
  white-space: nowrap;
  vertical-align: middle;
}

.codeTable tbody td.rotate {
  vertical-align: top;
  padding-top: 10px;
}

.codeTable tbody td.rotate * {
  margin-left: 50%; /* center "vertically" */
/* move half a line height and then flip direction of rendering */
/*
  transform: translateX(-0.61em) scale(-1);
  transform-origin: center;
}

.codeTable thead th.rotate * {
  margin-left: 50%; /* center "vertically" */
/* move half a line height and then flip direction of rendering */
/*
transform: translateX(-0.61em) scale(-1);
transform-origin: center;
}

.codeTable thead th.rotate {
vertical-align: bottom;
padding-bottom: 5px;
text-align: left;
}

.rotate * {
writing-mode: vertical-rl;
}
*/

.paraTable > table, .paraTable table td, .paraTable table th {
  border: none;
  margin: 0;
  padding: 0;
}

.level .wideTable.lightbox, .level .level .wideTable.lightbox, .level .level .level .wideTable.lightbox, .level .level .level .level .wideTable.lightbox, .level .level .level .level .level .wideTable.lightbox, .level .level .level .level .level .level .wideTable.lightbox {
  margin-left: 0;
}

.litem .wideTable.lightbox {
  margin-left: 0;
}

.negIndent {
  margin-left: -2em;
  margin-top: 14px;
  margin-bottom: 14px;
  width: auto;
}

.posIndent {
  margin-left: 2em;
  margin-top: 14px;
  margin-bottom: 14px;
  width: auto;
}

.soloContent {
  margin-top: 8px;
  margin-bottom: 12px;
}

#text .soloContent .nonxml {
  font-size: 16px;
  line-height: 20px;
}

.freeform ul {
  margin: 10px 0 0 1em;
  list-style-type: disc;
}

.freeform ol {
  counter-reset: item;
  margin: 10px 0;
  padding-left: 0;
}

.freeform ol ol {
  margin: 0;
  counter-reset: item2;
}

.freeform ol li {
  display: block;
  margin-left: 1.5em;
}

.freeform ol li:before {
  display: inline-block;
  content: counter(item) ".";
  counter-increment: item;
  width: 1.5em;
  margin-left: -1.5em;
}

.freeform ol ol li {
  margin-left: 2.5em;
}

.freeform ol ol li:before {
  display: inline-block;
  content: counter(item) "." counter(item2) ".";
  counter-increment: item2;
  width: 2.5em;
  margin-left: -2.5em;
}

/* pretend customization */

.annotationIcon {
  max-width: 100px;
  max-height: 100px;
  margin-top: 6px;
  float: left;
}

.annotation {
  /* background-color:#FCF; */
  padding: 9px 15px 15px 15px;
  border-top: 1px solid #999;
  border-bottom: 1px solid #999;
  margin-top: 30px;
  margin-bottom: 30px;
}

.annotationBody {
  overflow: hidden;
  padding-left: 20px;
}

.annotation-warning {
  /* background-color: #CFF; */;
}

.annotation .para {
  margin-top: 10px;
}

.annotation .para:first-of-type {
  margin-top: 0;
}

.annotation .annotationTitle {
  font-weight: 600;
}

.title .annotationIcon,
.positioned.bigTitle .annotationIcon,
.lineTitle .annotationIcon,
.barTitle .annotationIcon {
  max-width: 31px;
  max-height: 31px;
  /*padding-left: 49px;*/
  padding-left: 10px;
  margin-top: -5px;
}

#toc .barTitle .annotationIcon {
  margin-right: 8px;
  margin-top: -6px;
}

#toc .lineTitle .annotationIcon {
  margin-top: -10px;
  padding-left: 0;
}

#toc .positioned.bigTitle .annotationIcon {
  margin-top: -10px;
  padding-left: 0;
}

#toc .barTitle .annotation ~ .annotation .annotationIcon,
#toc .positioned.bigTitle .annotation ~ .annotation .annotationIcon,
#toc .lineTitle .annotation ~ .annotation .annotationIcon {
  margin-right: 0;
}

.title .annotation,
.positioned.bigTitle .annotation,
.lineTitle .annotation,
.barTitle .annotation {
  background-color: transparent;
  padding: 0;
  border: none;
  margin: 0;
  float: right;
}

.annotation:after {
  content: "";
  display: table;
  clear: both;
}

.ui-dialog .ui-button-icon-only .ui-button-text {
  line-height: 0;
}

#waiting {
  position: fixed;

  z-index: 20;

  width: 466px;

  top: 300px;
  left: 0;
  right: 0;

  margin-left: auto;
  margin-right: auto;
  padding-bottom: 32px;
  padding-top: 43px;

  background-color: #f2f2f2;

  outline: 1px solid #000;

  border: 10px solid #454545;
  border-top: 9px solid #454545;
  border-bottom: 9px solid #454545;

  box-shadow: 0 1px 10px rgba(0, 1, 1, 0.75), inset 0 1px 0 rgba(255, 255, 255, 0.15);
  -webkit-box-shadow: 0 1px 10px rgba(0, 1, 1, 0.75), inset 0 1px 0 rgba(255, 255, 255, 0.15);

  text-align: center;
}

#waitingTitle {
  padding-bottom: 12px;
  font-size: 18px;
  font-weight: 600;
  color: #656565;
}

@-moz-document url-prefix() {
  .modal {
    box-shadow: none;
  }
}

.noteFilterContainer {
  display: flex;
  justify-content: flex-end;
}

.muiTable {
  width: 100%;
  position: relative;
  display: table;
  border-spacing: 0;
  border-collapse: collapse;
  box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.12);
  border-radius: 2px;
}

.muiTable thead, .muiTable tbody {
  display: table-header-group;
}

.muiTable tr {
  color: inherit;
  height: 48px;
  display: table-row;
  outline: none;
  vertical-align: middle;
}

.muiTable thead tr {
  height: 56px;
}

.muiTable th, .muiTable td {
  display: table-cell;
  padding: 4px 8px 4px 16px;
  text-align: left;
  border-top: 1px solid rgba(224, 224, 224, 1);
  vertical-align: inherit;
}

.muiTable thead tr th {
  color: rgba(0, 0, 0, 0.54);
  font-size: 0.9rem;
  font-weight: 500;
}

.muiTable tbody tr td, .muiTable tbody tr th {
  color: rgba(0, 0, 0, 0.87);
  font-size: 1rem;
  font-weight: 400;
}

.muiTableIncludesDetailRow td {
  border-bottom-width: 0;
}

.muiTableDetailRow {
  height: 32px !important;
  padding-top: 0 !important;
}

.muiTableDetailRow td {
  padding: 0 8px 8px 32px !important;
  border-top: 0;
}

.muiTableDetailRow div {
  font-style: italic;
  font-size: .9rem !important;
}

.paper {
  box-sizing: border-box;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  -webkit-box-shadow: 0 1px 3px 0 rgb(0 0 0 / 20%), 0 1px 1px 0 rgb(0 0 0 / 14%), 0 2px 1px -1px rgb(0 0 0 / 12%);
  -moz-box-shadow: 0 1px 3px 0 rgb(0 0 0 / 20%), 0 1px 1px 0 rgb(0 0 0 / 14%), 0 2px 1px -1px rgb(0 0 0 / 12%);
  box-shadow: 0 1px 3px 0 rgb(0 0 0 / 20%), 0 1px 1px 0 rgb(0 0 0 / 14%), 0 2px 1px -1px rgb(0 0 0 / 12%);
  margin: 8px  0 8px;
  padding: 8px;
}

.paperColor {
  background-color: var(--paper-background-color);
}

.appBarSticky {
  top: var(--page-content-top);
  left: auto;
  right: 0;
  position: sticky;
  margin-bottom: 8px;
  z-index: 1;
}

/*.contactInfoPaper {*/
/*  display: inline-block;*/
/*}*/

.lawTableContainer {
  position: relative;
}

.lawTable .titleLawCell {
  min-width: 160px;
}

.lawTable .titleLawCell a {
  white-space: nowrap;
}

.lawTable .titleLawCell a span {
  white-space: normal;
}

.lawTable .adoptedLawCell {
  min-width: 88px;
}

.relatedLawsContainer {
  display: flex;
  margin: 16px 0 16px 0;
}

.relatedLawsIcon {
  width: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.relatedLawsIcon img {
  opacity: 0.54;
  height: 24px;
  width: 24px;
}

.relatedLawsPaper {
  flex: 1;
  border-left: 7px solid #454545;
  background-color: #f4f4f4;
  padding: 6px 16px 16px 24px;
  border-radius: 0;
}

.relatedLawsTitle {
  color: #1e1e1e;
  font-size: 24px;
  font-weight: 500;
  padding: 0 0 16px 0;
}

.relatedLawsContainer #lawTable {
  margin-top: 0;
}

.lawTableContainer #lawTable thead tr {
  height: 36px;
}

.lawTable tr.hidden {
  display: none;
}

.litem, .defitem {
  margin: 14px 0;
  clear: both;
}

.litem:last-child, .defitem:last-child, .level .content:last-child {
  margin-bottom: 0;
}


.litem table, .chapter table, .article table, .subarticle table, .section table {
  margin: 1px;
}

.litem_number, .defitem_number {
  display: block;
  text-decoration: none;
  color: #333;
  clear: both;
  float: left;
}

.litem_content, .defitem_content {
  margin-left: 2em;
}

.chapterrefblock, .attachmentsTitle {
  font-size: 15px;
  font-weight: bold;
  margin-top: 0.5em;
}

.chapterrefblock .para {
  margin-top: 0;
  margin-bottom: 0;
  font-weight: normal;
}

.foldertitle {
  font-weight: bold;
  font-size: 108%;
}

.legref {
  display: inline;
}

.footnote, .ftpara {
  display: inline;
}

.footnotes .footnote, .footnotes .ftpara {
  display: block;
}

.ftpara ~ .ftpara {
  display: block;
  margin-top: 10px;
}

.footnoterefnum ~ .ftpara {
  display: block;
  margin-left: 40px;
}

.footnoteref {
  font-size: 10px;
  font-weight: 600;
  color: #611;
  text-decoration: none;
  vertical-align: super;
}

.titleTitle .footnoteref {
  vertical-align: inherit;
  height: 0;
  overflow: visible;
  display: inline-block;
  position: relative;
  top: -7px;
}

#titleBox .footnoteref {
  color: #999;
}

.footnoterefnum {
  float: left;
  font-style: normal;
  color: #611;
  text-decoration: none;
}

.footnote {
  font-style: italic;
  font-weight: lighter;
  color: #611;
  font-size: 93%;
}

.content > .footnote, .content > .footnote .ftpara {
  display: block;
}

.term {
  font-weight: bold;
  display: block;
}

.deftext:first-child {
  margin-left: 0;
}

.termLink {
  color: #333;
  font-style: normal;
  text-decoration: none;
  cursor: default;
}

.attachmentsContainer .nonxml {
  display: block;
  font-size: 15px;
  margin-bottom: 0;
  line-height: 19px;
}

.chapterrefblock {
  margin-bottom: 10px;
}

.displayDate {
  margin-left: 7px;
}

.displayDate a {
  text-decoration: none;
  font-weight: bold;
  color: #333;
}

p {
  margin-bottom: 0;
  display: inline;
}

.chapterrefblock p {
  display: block;
}

p ~ p {
  display: block;
}

.big_title, .section {
  display: none;
}

.line {
  height: 22px;
  border-bottom: 1px black solid;
}

.litem .litem_content .title {
  text-decoration: underline;
  font-weight: normal;
  font-size: inherit;
  display: inline;
}

.litem .litem_content .title {
  padding-right: 4px;
}

.litem .litem_content .title + .para {
  display: inline;
  margin: 0;
}

.modal .landTable .tableScrollWrapper:after, .modal .landTable .tableScrollWrapper:before {
  display: none;
}

.modal .landTable, .modal .landTable table {
  cursor: default;
}

.codeTableLightbox.modal {
  top: 16px;
  right: 32px;
  bottom: 24px;
  left: 16px;
  border-right: 16px solid #454545;
  border-bottom: 16px solid #454545;
  border-left: 16px solid #454545;
}

.modal .landTable {
  height: calc(100% - 28px);
  margin-top: 0;
}

.modal .landTable .tableScrollWrapper {
  height: 100%;
  overflow-y: auto;
}

.modal .landTable .tableScrollWrapper table {
  width: 100%;
  transform: none;
}

.codeTableLightbox.modal .modalTitle {
  background-color: #454545;
  color: #999;
  margin-top: 0;
  min-height: 28px;
}

.appIcons {
  margin-top: 16px;
}

#displayDateInput, #updatedDateInput, #publishingIdentifierInput {
  width: 100%;
}

.archiveModalLine {
  margin-top: 8px;
}

.archiveManageButton {
  margin-top: -4px;
}